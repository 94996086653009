// Footer.js
import React from 'react';

const Footer = ({ styler }) => {
  const year = new Date().getFullYear();

  return (
    <footer className={"footer " + styler}>
      <div className="footer-left">
        <p>`Copyright © Todos los derechos reservados, México 2024 / <a href="https://www.fomentoculturalbanamex.org" target="_blank"> Fomento Cultural Banamex, A.C.</a></p>
      </div>
      <div className="footer-center">
        <p><a href="https://www.banamex.com/compromiso-social/avisos.html" target="_blank">Aviso de privacidad.</a></p>
        <p><a href="https://www.banamex.com" target="_blank">Visita www.banamex.com</a></p>
      </div>
      <div className="footer-right">
        {/* <img src="../demo/assets/citibanamex_140.png" alt="Citibanamex 140 años" /> */}
        <a href="https://www.banamex.com" target="_blank"><img src="../assets/citibanamex_140.png" alt="Citibanamex 140 años"></img> </a>
      </div>
    </footer>
  );
};




export default Footer;
